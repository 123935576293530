import { desktopStyles, makeRem, mobileStyles } from "@machineq/elements";
import React, { FC } from "react";
import styled, { css } from "styled-components";

type MQAppbarRowProps = {
  justify?: React.CSSProperties["justifyContent"];
};

const CSSAppbarRow = css<MQAppbarRowProps>`
  width: 100%;
  display: flex;
  justify-content: ${({ justify }) => justify};
  align-items: center;
  position: relative;

  ${mobileStyles} {
    overflow: hidden;
    &:not(:first-child) {
      display: none;
    }
  }

  ${desktopStyles} {
    &:not(:first-child) {
      margin-top: ${makeRem(16)};
      min-height: ${makeRem(40)};
    }
  }
`;

const StyledMQAppbarRow = styled.div<MQAppbarRowProps>`
  ${CSSAppbarRow}
`;

const StyledMQAppbarRowForm = styled.form<MQAppbarRowProps>`
  ${CSSAppbarRow};
`;

export const MQAppbarRow: FC<React.PropsWithChildren<MQAppbarRowProps>> = ({
  children,
  justify = "start"
}) => <StyledMQAppbarRow justify={justify}>{children}</StyledMQAppbarRow>;

export const MQAppbarRowForm: FC<
  React.PropsWithChildren<
    MQAppbarRowProps &
      React.DetailedHTMLProps<
        React.FormHTMLAttributes<HTMLFormElement>,
        HTMLFormElement
      >
  >
> = ({ children, justify = "start", onSubmit }) => (
  <StyledMQAppbarRowForm justify={justify} onSubmit={onSubmit}>
    {children}
  </StyledMQAppbarRowForm>
);
