import { desktopStyles } from "@machineq/elements";
import { ReactNode, forwardRef } from "react";
import styled from "styled-components";

const SDiv = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ${desktopStyles} {
    flex-direction: row;
  }
`;

export const LayoutFeature = forwardRef<
  HTMLDivElement,
  { children: ReactNode }
>(function LayoutFeature({ children, ...restProps }, ref) {
  return (
    <SDiv {...restProps} ref={ref}>
      {children}
    </SDiv>
  );
});
