import styled, { css } from "styled-components";

export const LayoutFeatureSectionBody = styled.article<{
  noScroll?: boolean;
  scrollContent?: boolean;
}>`
  flex: 1;
  width: 100%;

  ${({ noScroll }) =>
    !noScroll &&
    css`
      overflow: hidden;
    `}

  ${({ scrollContent = false }) =>
    scrollContent &&
    css`
      & > * {
        overflow-y: auto;
      }
    `}
`;
