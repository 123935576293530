import { ClientDebugger } from "@machineq/elements";
import { AssetSearchApiQueryParams } from "@machineq/models";

export const debugAssetSearch = new ClientDebugger("Asset Search", "#00144E");

/**
 * Hardcoded since these really don't exist in the DB. They're derived
 * values
 */
export const assetStatus = [
  {
    label: "Active",
    value: "ACTIVE"
  },
  {
    label: "Missing",
    value: "MISSING"
  },
  {
    label: "Untagged",
    value: "UNTAGGED"
  }
];

export const assetSearchFilterLabelMap: Partial<{
  [key in keyof AssetSearchApiQueryParams]: string;
}> = {
  building_id: "Building",
  campus_id: "Campus",
  room_id: "Room",
  search: "Search",
  status__in: "Status"
};

export const parseAssetStatusValue = (statusInValue: string | string[]) =>
  assetStatus.reduce((accum, status) => {
    if (
      typeof Array.isArray(statusInValue) &&
      statusInValue.includes(status.value)
    ) {
      if (accum === "") {
        return status.value;
      }
      return accum.concat(`, ${status.value}`);
    }
    return accum;
  }, "");
