import {
  Button,
  desktopStyles,
  Icon,
  makeColor,
  makeFontWeight,
  makeRem,
  makeRgba,
  useIsMobile,
  type ButtonProps,
  TypographyCopy,
  TypographyHeading
} from "@machineq/elements";
import { ArrowBack } from "@machineq/icons";
import React, { FC, memo, useMemo } from "react";
import styled from "styled-components";

const StyledTitle = styled(TypographyHeading).attrs({
  mqVariant: "h6",
  mqNode: "div"
})`
  color: ${makeColor("white")};

  ${desktopStyles} {
    color: ${makeColor("black")};
  }
`;

const StyledDetailPrimary = styled(TypographyHeading)`
  line-height: ${makeRem(23)};
  color: ${makeColor("white")};
  font-weight: ${makeFontWeight("regular")};

  ${desktopStyles} {
    color: ${makeColor("black")};
  }
`;

const StyledDetailSecondary = styled(TypographyCopy)`
  color: ${makeRgba("white", 0.6)} !important;

  && {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
  }

  ${desktopStyles} {
    color: ${makeColor("grey-5")};
  }
`;

const StyledDetailContainer = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;

  & > * {
    max-width: 100%;
  }
`;

const SContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  overflow: hidden;

  && > h1,
  h2,
  h3,
  h4,
  h5 {
    line-height: ${makeRem(24)};
  }
`;

export type MQAppbarTitleProps = {
  title?: string;
  detailPrimary?: string;
  detailSecondary?: string;
  action?: {
    onClick?: ButtonProps["onClick"];
    ariaLabel: string;
  };
};

const StyledButtonContainer = styled.div`
  margin-right: ${makeRem(16)};
`;

export const MQAppbarTitle: FC<React.PropsWithChildren<MQAppbarTitleProps>> =
  memo(function MQAppbarTitle({
    title,
    detailPrimary,
    detailSecondary,
    action
  }) {
    const doesClickExist = useMemo(
      () => typeof action?.onClick !== "undefined",
      [action?.onClick]
    );
    const isMobile = useIsMobile();

    const NavBackLink = useMemo(
      () =>
        doesClickExist &&
        action && (
          <StyledButtonContainer>
            <Button onClick={action.onClick} aira-label={action.ariaLabel}>
              <Icon
                mqColor={isMobile ? "white" : "black"}
                mqTitle="go back"
                accessibility="actionable"
              >
                <ArrowBack />
              </Icon>
            </Button>
          </StyledButtonContainer>
        ),
      [action, doesClickExist, isMobile]
    );

    if (title && !doesClickExist) {
      return <StyledTitle>{title}</StyledTitle>;
    }

    if (title && doesClickExist) {
      return (
        <StyledDetailContainer>
          {NavBackLink}
          <div>
            <StyledTitle>{title}</StyledTitle>
          </div>
        </StyledDetailContainer>
      );
    }

    const DetailPrimary = detailPrimary && (
      <StyledDetailPrimary mqVariant="subtitle1" mqNode="div">
        {detailPrimary}
      </StyledDetailPrimary>
    );

    const DetailSecondary = detailPrimary && (
      <StyledDetailSecondary mqVariant="caption" title={detailSecondary}>
        {detailSecondary}
      </StyledDetailSecondary>
    );

    if (detailPrimary || detailSecondary || doesClickExist) {
      return (
        <StyledDetailContainer>
          {NavBackLink}
          <SContainer>
            {DetailPrimary}
            {DetailSecondary}
          </SContainer>
        </StyledDetailContainer>
      );
    }

    return <></>;
  });
