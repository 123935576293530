import styled, { css } from "styled-components";

export type LayoutFeatureSectionProps = { expand?: boolean };
export const LayoutFeatureSection = styled.section<LayoutFeatureSectionProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;

  ${({ expand = false }) =>
    expand
      ? css`
          flex-grow: 10;
        `
      : css`
          flex: 1;
        `}
`;
