import {
  desktopStyles,
  makeColor,
  makeRem,
  makeRgba,
  mobileStyles
} from "@machineq/elements";
import { Container } from "@pwa/components/container";
import clsx from "clsx";
import React, { FC, ReactNode } from "react";
import styled from "styled-components";

type MQAppbarProps = {
  ActionComponent?: ReactNode;
  size?: "condensed" | "expanded";
  disableMaxWidth?: boolean;
  children: ReactNode;
  className?: string;
};

const StyledMQAppbar = styled.div`
  width: 100%;

  box-sizing: border-box;
  position: relative;

  background: ${makeColor("primary-dark")};
  box-shadow: ${`0 3px 4px 0 ${makeRgba("black", 0.02)},
    0 3px 3px -2px ${makeRgba("black", 0.06)}`};

  ${mobileStyles} {
    height: ${makeRem(57)};
    padding: 0;

    &::after {
      content: "";
      position: absolute;
      background: ${makeColor("custom-gradient-eclipse")};
      height: ${makeRem(4)};
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
    }
  }

  ${desktopStyles} {
    background: ${makeColor("white")};
    border-bottom: ${makeRem(1)} solid ${makeColor("custom-rule-40")};
    padding-top: ${makeRem(16)};
    max-height: initial;
    padding-bottom: ${makeRem(36)};

    &.condensed {
      padding-bottom: ${makeRem(24)};
    }

    &.no-max-width {
      & > * {
        max-width: initial !important;
      }
    }
  }
`;

const AppbarLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const MQAppbar: FC<React.PropsWithChildren<MQAppbarProps>> = ({
  children,
  size = "condensed",
  ActionComponent = undefined,
  disableMaxWidth = false,
  className
}) => (
  <StyledMQAppbar
    className={clsx(className, {
      condensed: size === "condensed",
      "no-max-width": disableMaxWidth
    })}
  >
    <Container>
      <AppbarLayout>
        {children}
        {ActionComponent}
      </AppbarLayout>
    </Container>
  </StyledMQAppbar>
);
