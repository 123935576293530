import { FeatureLayoutComponent } from "@pwa/features/page";
import { RootLayout } from "@pwa/features/root";
import React from "react";

export const AssetSearchPageLayout: FeatureLayoutComponent = ({ children }) => {
  return <>{children}</>;
};

AssetSearchPageLayout.getLayout = (page) =>
  RootLayout.getLayout(<AssetSearchPageLayout>{page}</AssetSearchPageLayout>);
