import { desktopStyles, makeRem, mobileStyles } from "@machineq/elements";
import React, { FC, ReactNode } from "react";
import styled from "styled-components";

interface ContainerStandardProps {
  isSticky?: boolean;
  children: ReactNode;
}

const StyledContainer = styled.div<ContainerStandardProps>`
  height: 100%;
  overflow-y: auto;
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  flex-direction: inherit;

  ${mobileStyles} {
    padding-left: ${makeRem(16)};
    padding-right: ${makeRem(16)};
  }
  ${desktopStyles} {
    padding-left: ${makeRem(32)};
    padding-right: ${makeRem(32)};
  }
`;

export const Container: FC<React.PropsWithChildren<ContainerStandardProps>> = ({
  children,
  isSticky = false
}) => (
  // $ denotes a transient property that won't be forwarded
  // onto the DOM

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <StyledContainer $isSticky={isSticky}>{children}</StyledContainer>
);
